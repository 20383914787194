import { ApolloClient, from, InMemoryCache, ServerParseError, split } from '@apollo/client';
import { ErrorLink } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import SnackbarUtils from '@src/components/shared/SnackBarUtils';
import { createUploadLink } from 'apollo-upload-client';
import { Kind, OperationTypeNode } from 'graphql';
// import { createSpanLink } from '@src/tracer';
import { createClient } from 'graphql-ws';

const httpLink = from([
    // createSpanLink,
    new ErrorLink(({ networkError, graphQLErrors }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message }) => {
                SnackbarUtils.error(message);
            });
        }

        if (networkError) {
            if ((networkError as ServerParseError).statusCode === 401) {
                window.location.href = '/api/login';
            } else {
                SnackbarUtils.error(networkError.message);
            }
        }
    }),
    createUploadLink({
        uri: `${import.meta.env.VITE_API_BASE_URI}/graphql`,
    }),
]);

const wsLink = new GraphQLWsLink(
    createClient({
        url: `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${
            window.location.hostname
        }${import.meta.env.PROD ? '' : ':4000'}/api/graphql`,
        on: {
            connected: () => {
                // eslint-disable-next-line no-console
                console.log('Subscription connected');
            },
            closed: () => {
                // eslint-disable-next-line no-console
                console.log('Subscription closed');
            },
        },
    }),
);

const link = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === Kind.OPERATION_DEFINITION &&
            definition.operation === OperationTypeNode.SUBSCRIPTION
        );
    },
    wsLink,
    httpLink,
);

export const getApolloClient = () =>
    new ApolloClient({
        link,
        cache: new InMemoryCache({
            addTypename: false,
        }),
    });

export default getApolloClient;

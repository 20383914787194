import { createContext, Dispatch, SetStateAction } from 'react';

type SetState<T> = Dispatch<SetStateAction<T>>;

type BlockActionContextType = {
    blockAction: (action: () => void, blockIf: boolean, dialogOptions?: DialogOptions) => void;
    open: boolean;
    setOpen: SetState<boolean>;
    dialogOptions: DialogOptions;
};

export type DialogOptions = {
    title?: string;
    description?: string | JSX.Element;
    confirm?: string;
    cancel?: string;
    confirmAction?: () => void;
    cancelAction?: () => void;
    actionsPosition?: 'center' | 'start' | 'end';
};

const BlockActionContext = createContext<BlockActionContextType | null>(null);

export default BlockActionContext;

import { ContentType, ContentTypeGroup, ObjectSchema } from '@src/__generated__/types';
import { detonate, DETONATE_ERRORS } from '@src/utils';
import React, { createContext } from 'react';

export enum ActionKind {
    RESET = 'RESET',
    PRODUCT = 'PRODUCT',
    CONTENT_TYPE = 'CONTENT_TYPE',
    CONTENT_TYPE_GROUP = 'CONTENT_TYPE_GROUP',
}

type Action =
    | { type: ActionKind.PRODUCT; payload: ObjectSchema }
    | {
          type: ActionKind.CONTENT_TYPE_GROUP;
          payload: { product: ObjectSchema; contentTypeGroup: ContentTypeGroup };
      }
    | { type: ActionKind.CONTENT_TYPE; payload: ContentType }
    | { type: ActionKind.RESET };

type SelectedState =
    | Record<string, never>
    | { product: ObjectSchema }
    | { product: ObjectSchema; contentTypeGroup: ContentTypeGroup }
    | {
          product: ObjectSchema;
          contentTypeGroup: ContentTypeGroup;
          contentType: ContentType;
      };

export const productTreeReducer = (state: SelectedState, action: Action): SelectedState => {
    if (action.type === ActionKind.RESET) return {};
    if (action.type === ActionKind.PRODUCT) {
        return { product: action.payload };
    }
    // TODO: handle error with toast
    if (!('product' in state)) detonate(DETONATE_ERRORS.PRODUCT_INFO_NOT_SELECTED);

    switch (action.type) {
        case ActionKind.CONTENT_TYPE:
            return {
                product: state.product,
                // TODO: handle error with toast
                contentTypeGroup:
                    'contentTypeGroup' in state
                        ? state.contentTypeGroup
                        : detonate(DETONATE_ERRORS.CONTENT_TYPE_GROUP_NOT_SELECTED),
                contentType: action.payload,
            };
        case ActionKind.CONTENT_TYPE_GROUP:
            return {
                product: action.payload.product,
                contentTypeGroup: action.payload.contentTypeGroup,
            };
        default:
            return state;
    }
};

export const ProductTreeContext = createContext<{
    state: SelectedState;
    dispatch: React.Dispatch<Action>;
}>({
    state: {},
    dispatch: () => null,
});

import {
    AccountCircleIcon,
    AccountTreeIcon,
    AppBar,
    Box,
    BusinessCenterIcon,
    Button,
    LanguageIcon,
    ListIcon,
    LocalOfferIcon,
    Menu,
    MenuItem,
    SearchIcon,
    TranslateIcon,
    WorkIcon,
} from '@ds/coolshop';
import {
    path as translationsPath,
    useTranslations,
} from '@src/components/translations/Translations';
import { useAuth } from '@src/hooks';
import { LanguageContext } from '@src/store';
import useBlockActionContext from '@src/store/BlockActionContext/BlockActionContext.hook';
import { toTitleCase } from '@src/utils';
import { ReactNode, useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { SettingsContext } from '../../modules/setting/SettingsContext';
import { useDirtyContext } from '../hierarchies/shared/DirtyContext';

type LinkToProps = {
    dest: string;
    disabled?: boolean;
    icon: ReactNode;
};

const LinkTo = ({ dest, icon, disabled }: LinkToProps) => {
    const intl = useIntl();
    const { pathname } = useLocation();

    const navigate = useNavigate();
    const { blockAction } = useBlockActionContext();
    const { isDirty } = useDirtyContext();

    const destination = `/${dest}`;

    const handleClick = () => {
        blockAction(() => navigate(destination), isDirty);
    };

    const active = pathname.startsWith(destination);

    return (
        <Button
            disabled={disabled}
            startIcon={icon}
            variant="text"
            color={active ? 'secondary' : 'primary'}
            invertedColor={!active}
            onClick={handleClick}
        >
            {intl.formatMessage({
                id: dest,
                defaultMessage: toTitleCase(dest),
            })}
        </Button>
    );
};

/**
 * Cool PIM Header [Language |  Products | Hierarchies]
 */
export function PimAppBar(): JSX.Element {
    const intl = useIntl();
    const { defaultLanguage } = useContext(LanguageContext);
    const { enableSearch, theme: themeSettings } = useContext(SettingsContext);
    const { hasUserRole, sessionData } = useAuth();
    const hasLanguages = useMemo(() => !!defaultLanguage, [defaultLanguage]);
    const { canTranslate } = useTranslations();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const displayUserName =
        sessionData?.name || sessionData?.surname
            ? `${sessionData?.name} ${sessionData.surname}`
            : sessionData?.username;

    return (
        <AppBar position="fixed" color="primary">
            <Box
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box display="flex">
                    <img
                        style={{ height: '32px', marginRight: '40px' }}
                        alt="CoolPim"
                        src={sessionData?.attributes?.logo ?? themeSettings?.logo ?? '/logo.png'}
                    />
                    <Box display="flex" gap={2} marginLeft={t => t.spacing(1)}>
                        {hasUserRole('PIM_ADMIN') && (
                            <LinkTo dest="language" icon={<LanguageIcon />} />
                        )}
                        {hasUserRole('PIM_ADMIN') && (
                            <LinkTo
                                dest="products"
                                icon={<BusinessCenterIcon />}
                                disabled={!hasLanguages}
                            />
                        )}
                        <LinkTo
                            dest="hierarchies"
                            icon={<AccountTreeIcon />}
                            disabled={!hasLanguages}
                        />
                        {hasUserRole('PIM_ADMIN') && (
                            <LinkTo dest="jobs" icon={<WorkIcon />} disabled={!hasLanguages} />
                        )}
                        {canTranslate && (
                            <LinkTo dest={translationsPath} icon={<TranslateIcon />} />
                        )}
                        {enableSearch && (
                            <LinkTo dest="search" icon={<SearchIcon />} disabled={!hasLanguages} />
                        )}
                        {hasUserRole('CGT_PRICELIST_DISCOUNT') && (
                            <LinkTo
                                dest="discounts"
                                icon={<LocalOfferIcon />}
                                disabled={!hasLanguages}
                            />
                        )}
                        {hasUserRole('CGT_PRICELIST') && (
                            <LinkTo
                                dest="short_lists"
                                icon={<ListIcon />}
                                disabled={!hasLanguages}
                            />
                        )}
                    </Box>
                </Box>
                {displayUserName && (
                    <Box display="flex" alignItems="center" flexWrap="wrap" marginLeft="auto">
                        <Button
                            variant="text"
                            color="primary"
                            invertedColor
                            startIcon={<AccountCircleIcon />}
                            onClick={handleClick}
                        >
                            {displayUserName}
                        </Button>

                        <Menu
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                        >
                            <MenuItem
                                onClick={() => {
                                    window.location.href = '/api/logout';
                                }}
                            >
                                {intl.formatMessage({ defaultMessage: 'Logout', id: 'logout' })}
                            </MenuItem>
                        </Menu>
                    </Box>
                )}
            </Box>
        </AppBar>
    );
}

export default PimAppBar;
